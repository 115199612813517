import React from 'react';
import styled from 'styled-components';

import { Text } from '@components';

const Container = styled.div`
  position: relative;
  text-align: center;
  height: 790px;

  @media (min-width: 450px) {
    height: 1000px;
  }
`;

const SectionTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  height: 47%;
  background-color: ${({ color }) => color && color};
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-position: center bottom;

  @media (min-width: 450px) {
    height: 58%;
    background-size: 830px 542px;
  }
`;

const SectionBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  height: 53%;
  background-color: ${({ color }) => color && color};
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: 831px 415px;
  background-position: 51% top;

  @media (min-width: 450px) {
    height: 42%;
  }
`;

const Content = styled.div`
  padding: 16px 14px;
  max-width: 380px;
`;

const LayoutMobile = ({ headerData, aboutData }) => (
  <Container>
    <SectionTop color="#4c8ff9" image={headerData.imageMobile || headerData.image}>
      <Content>
        <Text.Heading2 mb={24}>{headerData.title}</Text.Heading2>
        <Text.Body2>{headerData.description}</Text.Body2>
      </Content>
    </SectionTop>
    <SectionBottom color="#f1f7fa" image={aboutData.imageMobile || aboutData.image}>
      <Content>
        <Text.Heading2 mb={16}>{aboutData.title}</Text.Heading2>
        <Text.Body2 whiteSpace="pre-line" color="darkBlue_80">
          {aboutData.description}
        </Text.Body2>
      </Content>
    </SectionBottom>
  </Container>
);

export default LayoutMobile;
