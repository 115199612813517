import React from 'react';

import { Box, Show } from '@core';
import { LanguagePicker } from '@components';

import { LayoutDesktop, LayoutMobile } from './layouts';

const AdoptionIndexHeader = ({ headerData = {}, aboutData = {} }) => (
  <React.Fragment>
    <Box
      position="absolute"
      height={80}
      width="100%"
      right={{ _: 0, lg: 16 }}
      top={0}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      zIndex={1}
    >
      <Box mr={{ md: 44 }}>
        <LanguagePicker />
      </Box>
    </Box>
    <Show.TabletLarge>
      <LayoutMobile headerData={headerData} aboutData={aboutData} />
    </Show.TabletLarge>

    <Show.DesktopAndUp>
      <LayoutDesktop headerData={headerData} aboutData={aboutData} />
    </Show.DesktopAndUp>
  </React.Fragment>
);

export default AdoptionIndexHeader;
