import React from 'react';
import styled from 'styled-components';

import { Text } from '@components';

const Container = styled.div`
  position: relative;
  height: 1300px;
  text-align: center;
`;

const SectionTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  height: 55%;
  background-color: ${({ color }) => color && color};
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-position: center bottom;
`;

const SectionBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  height: 45%;
  background-color: ${({ color }) => color && color};
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-position: 50.3% top;
`;

const Content = styled.div`
  padding: 24px 0;
  max-width: 580px;
`;

const LayoutDesktop = ({ headerData, aboutData }) => (
  <Container>
    <SectionTop color="#4c8ff9" image={headerData.image}>
      <Content>
        <Text.Heading1 mb={32}>{headerData.title}</Text.Heading1>
        <Text.Body1>{headerData.description}</Text.Body1>
      </Content>
    </SectionTop>
    <SectionBottom color="#f1f7fa" image={aboutData.image}>
      <Content>
        <Text.Heading1 mb={32}>{aboutData.title}</Text.Heading1>
        <Text.Body2 whiteSpace="pre-line" color="darkBlue_80">
          {aboutData.description}
        </Text.Body2>
      </Content>
    </SectionBottom>
  </Container>
);

export default LayoutDesktop;
